.BlockButton {
    grid-column: 4 / 5;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: flex-start;
}

.BlockButton-button {
    min-height: 6vh;
    min-width: 22vh;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: calc(10px + 2vmin);
    color: #ffffff;

    margin-bottom: 30px;
}

.bug {
    max-height: 6vh;
    max-width: 22vh;
    white-space: nowrap;
}