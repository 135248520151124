.BlockSelect {
  grid-column: 1 / 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.BlockSelect-label {
  background-color: #ffffff;
  font-size: calc(10px + 2vmin);
  color: gray;
}

.SelectItem {
  margin: 20px 0;
}

.BlockSelect-input {
  margin-top: 10px;
  background-color: #ffffff;
  min-height: 5vh;
  min-width: 40vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: grey;
}

.BlockSelect-button_draw {
  padding: 10px 26px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
