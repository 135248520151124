.ModalWindow {
    border-style: solid;
    border-color: black;
    background: #d9d9d9;
    position: absolute;
    width: 450px;
    height: 150px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.container-column {
    float: left;
}

.container-input {
    display: flex;
    flex-direction: column;
}

.container-box_button {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.h4 {
    height: 10px;
}

.Form {
    margin-left: 20px;
}