.AppHeader {
  margin-top: 20px;
}

.AppHeader-title {
  display: flex;
  justify-content: center;
  font-size: 42px;
  font-weight: 900;
  color: gray;
}

.AppHeader-button:hover {
  color: black;
  border: none;
}

.AppHeader-button:focus {
  outline: none;
}

.AppLink {
  border: none;
  display: flex;
  justify-content: flex-end;
}

.AppLink,
.AppLink:hover {
  text-decoration: none;
}

.AppHeader-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-top: -72px;
  color: grey;
  font-size: calc(10px + 2vmin);
  background: #fff;
}

.AppHeader-image {
  margin: 15px;
  height: 2vh;
  width: 5vh;
}

.btn-left {
  flex-direction: row-reverse;
}

.img-left {
  transform: scaleX(-1);
}
