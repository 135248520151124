.Item {
  text-align: center;
  margin: 20px 3vw;
}

.Image-container {
  position: relative;
}

.size3 {
  max-height: 18vmin;
  max-width: 18vmin;
}

.size4 {
  max-height: 24vmin;
  max-width: 24vmin;
}

.size6 {
  max-height: 36vmin;
  max-width: 36vmin;
}

.Title {
  overflow-wrap: break-word;
}

.Image-container-text {
  position: absolute;
  color: saddlebrown;
  bottom: 4px;
  margin-left: -46px;
  font-weight: 900;
  width: 40px;
  font-size: 22px;
  line-height: 22px;
  padding: 2px 0;
  border-radius: 10px;
}

.Image-container-button-delete {
  width: 28px;
  height: 28px;
  color: white;
  border-radius: 50%;
  position: absolute;
  margin-top: -14px;
  margin-left: -14px;
  cursor: pointer;
}

.Image-container-button-delete:hover {
  border: solid 1px darkgray;
}
