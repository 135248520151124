.Warehouse {
    display: flex;
    flex-direction: column;
    max-height: 75vh;
}

.Grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.Button-sell {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10vw;
    text-transform: uppercase;
    margin-left: -5vw;
}

.Field-container {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Field-container-item {
    margin: 0 14px;
}

.Input-field {
    max-width: 150px;
}

.Input-check {
    width: 25px;
    height: 25px;
}

.WarehouseAlert {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-alert {
    margin-top: 5px;
}