.WorkshopBody {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    min-height: 70vh;
}

.Image {
    grid-column: 2 / 4;
    display: flex;
    justify-content: center;
    align-items: center;
}
